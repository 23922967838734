import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function DenseTable() {
  const show_client = false;

  const [show, setShow] = React.useState(show_client);

  const [rowsState, setRowsState] = React.useState([{ name: "", id: 1 }]);
  const navigate = useNavigate();

  // when the component is mounted, fetch the data
  React.useEffect(() => {
    var rows: { name: string; id: number }[] = [];
    var mail = JSON.parse(localStorage.getItem("user") || "{}").mail;

    axios
      .get("https://intranet.al-expertise.fr/getdata.php", {
        params: {
          mail: mail,
        },
      })
      .then((response) => {
        var data = response.data.data;
        console.log(data);
        var dataLength = data.length;
        if (dataLength > 0) {
          data.forEach((element: any) => {
            rows.push({ name: element.name, id: element.id });
          });
          setRowsState(rows);

          setShow(true);
        } else {
          setShow(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function Modifier(id: number) {
    axios
      .post("https://intranet.al-expertise.fr/modifier.php", {
        id: id,
      })
      .then(function (response) {
        if (response.data.status === "success") {
          var base64 = response.data.data.data;
          // transform base64 to json
          var json = JSON.parse(atob(base64));

          // save json form to local storage
          var form = json.form;
          localStorage.setItem("formRestore", JSON.stringify(form));
          localStorage.setItem("form", JSON.stringify(form));

          var calculateur = json.calculateur;
          localStorage.setItem(
            "calculateurRestore",
            JSON.stringify(calculateur)
          );
          localStorage.setItem("calculateur", JSON.stringify(calculateur));
          var devis = json.devis;
          localStorage.setItem("devisRestore", JSON.stringify(devis));
          localStorage.setItem("devis", JSON.stringify(devis));
          var note = json.note;
          localStorage.setItem("note", JSON.stringify(note));
          navigate("/entretien-client");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getData = React.useRef(() => {
    var rows: { name: string; id: number }[] = [];
    var mail = JSON.parse(localStorage.getItem("user") || "{}").mail;

    axios
      .get("https://intranet.al-expertise.fr/getdata.php", {
        params: {
          mail: mail,
        },
      })
      .then((response) => {
        var data = response.data.data;
        console.log(data);
        var dataLength = data.length;

        if (dataLength > 0) {
          data.forEach((element: any) => {
            rows.push({ name: element.name, id: element.id });
          });
          setRowsState(rows);
          setShow(true);
        } else {
          setShow(false);
        }
      })
      .catch((dataErrors) => {
        console.log(dataErrors);
      });
  });

  function Delete(id: number) {
    const config = {
      data: {
        id: id,
      },
    };
    axios
      .delete("https://intranet.al-expertise.fr/deletedata.php", config)
      .then((responseDelete) => {
        var data = responseDelete.data;
        if (data.status === "success") {
          var rows = rowsState.filter((row) => row.id !== id);
          setRowsState(rows);
          if (rows.length === 0) {
            setShow(false);
            // reset localStorage
            localStorage.removeItem("formRestore");
            localStorage.removeItem("calculateurRestore");
            localStorage.removeItem("devisRestore");
            localStorage.removeItem("formValid");
            localStorage.removeItem("note");
          }
        }
      })
      .catch((dataErrors) => {
        console.log(dataErrors);
      });
  }

  /*   React.useEffect(() => {
    getData.current();
  }, []); */

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 350 }} size="small" aria-label="a table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {show ? (
            rowsState.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right" sx={{ color: "#004AAD" }}>
                  <Button
                    sx={{ color: "white" }}
                    variant="contained"
                    onClick={() => Modifier(row.id)}
                  >
                    Modifier
                  </Button>
                </TableCell>
                <TableCell align="right" sx={{ color: "#FD1D1D" }}>
                  <Button
                    sx={{ color: "white", backgroundColor: "#FD1D1D" }}
                    variant="contained"
                    onClick={() => Delete(row.id)}
                  >
                    supprimer
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center" scope="row">
                Pas de clients
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
