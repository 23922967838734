import * as React from "react";
import Login from "./pages/Login";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import EntretienClient from "./pages/EntretienClient";
import Clients from "./pages/Clients";
import SideBar from "./components/SideBar/SideBar";
import Header from "./components/Header/Header";
/* import Dashboard from "./pages/Dashboard"; */
import styled from "@emotion/styled";
import UpButton from "./components/UpButton/UpButton";
import {IUserDispatchType, useUser} from "./context/userContext/userContext";

function App() {
  const { state } = useUser();
  const { dispatch } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    // if state.username is null, redirect to login page
    var user = JSON.parse(localStorage.getItem("user") || "{}");
    console.log(user.username);    
    if (!user.username) {
      console.log("redirect to login");
      dispatch({
        mail: "",
        isConnected: false,
        nom: "",
        prenom: "",
        type: IUserDispatchType.deconnection,
        username: "",
      });

      navigate("/login");
    }
  }, [state.username, dispatch, navigate]);

  return (
    <>
      {state.isConnected ? (
        <>
          <Header />
          <SideBar />
          <Dashboard>
            <Routes>
              <Route path="entretien-client" element={<EntretienClient />} />
              <Route path="clients" element={<Clients />}  />
            </Routes>
          </Dashboard>
          <UpButton />
        </>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;

const Dashboard = styled.div`
  height: calc(100vh - 100px);
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 214px);
`;
