import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { IUserDispatchType, useUser } from "../context/userContext/userContext";
import { useNavigate } from "react-router-dom";
import bgHero from "../assets/images/connexion.jpg";
import logo from "../assets/images/Logotype-ALExpertise.png";
import {
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";

const theme = createTheme();

export default function Login() {
  const { dispatch } = useUser();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    /*    const data = new FormData(event.currentTarget); */
    const username = event.currentTarget.username.value;
    const password = event.currentTarget.password.value;
    var data = JSON.stringify({
      username: username,
      password: password,
    });

    axios
      .post("https://intranet.al-expertise.fr/login.php", data)
      .then(function (response) {
        let dataFetch = response.data;

        if (dataFetch.status) {
          dispatch({
            type: IUserDispatchType.login,
            ...dataFetch,
          });
          navigate("/");
        }
        if (dataFetch === "username or password incorrect") {
          setError({ type: "WRONG_PASSWORD_OR_USERNAME" });
        }
      })
      .catch(function (error) {
        setError({ type: "INTERNAL_ERROR" });
      });
  };

  const [error, setError] = useState({ type: "" });

  const ErrorRender = () => {
    switch (error.type) {
      case "WRONG_PASSWORD_OR_USERNAME":
        return (
          <Typography color={"red"}>
            Nom d'utilisateur ou mot de passe saisie est incorrect
          </Typography>
        );
      case "INTERNAL_ERROR":
        return (
          <Typography color={"red"}>
            Erreur interne, veuiller ressayer plus tard
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <div>
          <Container maxWidth={false} disableGutters>
            <div>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: 300 }}>
                    <Box className={"img-container"}>
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                          marginBottom: "50px"
                        }}
                        src={logo}
                        alt="logo"
                      />
                    </Box>
                    <Box
                      className={"login-container"}
                      sx={{ padding: "0 16px" }}
                    >
                      <form onSubmit={handleSubmit}>
                        <TextField
                          sx={{ marginBottom: "16px" }}
                          fullWidth
                          variant={"filled"}
                          label={"Votre nom d'utilisateur"}
                          required
                          name="username"
                          autoComplete={"username"}
                          type={"text"}
                        />
                        <FormControl
                          sx={{ marginBottom: "16px" }}
                          fullWidth
                          variant="filled"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Mot de passe
                          </InputLabel>
                          <FilledInput
                            fullWidth
                            required
                            name="password"
                            autoComplete={"password"}
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <Box>
                          <ErrorRender />
                        </Box>
                        <Box display={"flex"}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={"remembered_login"}
                                  defaultChecked
                                />
                              }
                              label="Rester connecté"
                            />
                          </FormGroup>
                          <Button type={"submit"} variant={"outlined"} sx={{color:"#004AAD"}}>
                            Envoyer
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={8} sx={{ height: "100vh;" }}>
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    src={bgHero}
                    alt="bgHero"
                  />
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </main>
    </ThemeProvider>
  );
}
